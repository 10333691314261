<template>
	<main>
		<div class="el-content">
			<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
			<div class="action-box">
				<div class="action">
					<el-button class="el-icon-refresh" @click="all"></el-button>
					<template v-if="import_visible">
						<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
						<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
							<input type="file" id="file" @change="uploadFile" accept=".xlsx">
						</el-button>
					</template>
				</div>
				<div class="other">
					<table-column :column="column" @change="changeColumn"/>
					<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
				</div>
			</div>
			<div class="table-box">
				<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
					<template #column="{scope,item}"></template>
					<template #action="{scope}">
						<div class="el-link-group">
							<el-link type="primary" @click="toEvent(scope.row)">行为日志</el-link>
							<el-link type="primary" @click="toScore(scope.row)">积分日志</el-link>
						</div>
					</template>
				</table-table>
			</div>
			<div class="page-box">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
			</div>
		</div>
		<table-import ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		<components-event ref="eventRef"/>
		<components-score ref="scoreRef"/>
	</main>
</template>
<script>
import {user as api} from "@/api/admin";
import {MixinIndex} from "@/service/admin/mixin";
import ComponentsEvent from "./components/index/Event.vue"
import ComponentsScore from "./components/index/Score.vue"

export default {
	components:{ComponentsEvent,ComponentsScore},
	mixins:[MixinIndex],
	data(){
		return {
			title:"用户",
			column_option: {
				status: [{id: 1, title: "正常", type: "success"}, {id: 2, title: "草稿", type: "danger"}],
			},
			import_visible:false,
			export_visible:false,
		}
	},
	computed:{
		import_field(){
			return []
		},
		export_field(){
			return []
		},
		column(){
			return [
				{label:"ID",prop:"id",width: 100,sortable:true},
				{label:"头像",prop:"avatar",type:"image"},
				{label:"姓名",prop:"username", search:true},
				{label:"手机号码",prop:"phone", search:true},
				{label:"邮箱",prop:"email", search:true},
				{label:"公司",prop:"company"},
				{label:"职位",prop:"position"},
				{label:"积分",prop:"score",sortable: true},
				{label:"感兴趣的产品",prop:"message",search: true},
				{label:"注册时间",prop:"create_time",type:'datetime',sortable:true},
				{label:"最近登录时间",prop:"login_time",type:'datetime',sortable:true},
				{label:"操作",prop:"action",fixed:'right',type:'action'},
			]
		}
	},
	mounted() {
		this.all();
	},
	methods:{
		toEvent(row){
			this.$refs.eventRef.init(row);
		},
		toScore(row){
			this.$refs.scoreRef.init(row);
		},
		privateAll(params){
			return api.all(params);
		},
		privateDeleted(row){
			return api.delete({
				id:row.id
			})
		},
		privateExportAll(params){
			return api.all(params)
		},
		privateSaveAll(list){
			return api.import(list)
		},
		privateChangeSort(data){
			return api.changeOrder(data)
		}
	}
}
</script>
<style lang="scss" scoped></style>
