<template>
	<main v-if="visible">
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{ title }}</div>
			</template>
			<div class="el-content">
				<table-search v-model="search_data" :column="column" @clean="clean" @search="search"/>
				<div class="action-box">
					<div class="action">
						<el-button class="el-icon-refresh" @click="all"></el-button>
						<template v-if="import_visible">
							<el-button class="el-icon-download" @click="templateFile">下载导入模板</el-button>
							<el-button class="upload-btn el-icon-upload" :loading="upload_loading">批量导入
								<input type="file" id="file" @change="uploadFile" accept=".xlsx">
							</el-button>
						</template>
					</div>
					<div class="other">
						<table-column :column="column" @change="changeColumn"/>
						<el-button v-if="export_visible" class="el-icon-download" @click="exportAll" :loading="export_loading" title="导出"></el-button>
					</div>
				</div>
				<div class="table-box">
					<table-table :table_loading="table_loading" :table_data="table_data" :column_visible="column_visible" :column="column" @selection="selection" @sortChange="sortChange" @changeSort="changeSort">
						<template #column="{scope,item}"></template>
						<template #action="{scope}">
							<div class="el-link-group">
								<el-popconfirm title="确定要删除该数据吗？" @confirm="deleted(scope.row)">
									<template #reference>
										<el-link type="primary" >删除</el-link>
									</template>
								</el-popconfirm>
							</div>
						</template>
					</table-table>
				</div>
				<div class="page-box">
					<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page_info.page" :page-sizes="[10, 15, 20, 50,100]" :page-size="page_info.limit" layout="total,sizes,prev,pager,next,jumper" :total="page_info.total" hide-on-single-page background></el-pagination>
				</div>
			</div>
			<table-import ref="importRef" :import_field="import_field" @saveAll="saveAll"/>
		</el-dialog>
	</main>
</template>
<script>
import {user_event as api} from "@/api/admin";
import {MixinIndex} from "@/service/admin/mixin";

export default {
	mixins:[MixinIndex],
	data(){
		return {
			visible:false,
			title:"行为日志",
			column_option: {

			},
			import_visible:false,
			export_visible:true,
		}
	},
	computed:{
		import_field(){
			return []
		},
		export_field(){
			return [
				{label:"姓名",prop:"username"},
				{label:"手机号码",prop:"phone"},
				{label:"邮箱",prop:"email"},
				{label:"事件标识",prop:"event"},
				{label:"事件详情",prop:"title"},
				{label:"积分",prop:"score"},
				{label:"发生时间",prop:"create_time",type:'datetime',sortable:true},
			]
		},
		column(){
			return [
				{label:"ID",prop:"id",width: 100,sortable:true},
				{label:"姓名",prop:"username"},
				{label:"手机号码",prop:"phone"},
				{label:"邮箱",prop:"email"},
				{label:"事件标识",prop:"event",search:true},
				{label:"事件详情",prop:"title",search:true},
				{label:"积分",prop:"score"},
				{label:"发生时间",prop:"create_time",type:'datetime',sortable:true,search:true},
			]
		}
	},
	methods:{
		close(){
			this.visible = false;
		},
		init(query = {}){
			this.query = query;
			this.visible = true;
			this.all();
		},
		privateAll(params){
			params.user_id = this.query.id;
			return api.all(params);
		},
		privateDeleted(row){
			return api.delete({
				id:row.id
			})
		},
		privateExportAll(params){
			return api.all(params)
		},
		privateSaveAll(list){
			return api.import(list)
		},
		privateChangeSort(data){
			return api.changeOrder(data)
		},
	}
}
</script>
<style lang="scss" scoped>
.el-content{min-height: 0;}
</style>
